const colors = {
  primary: '#35519f',
  primaryText: '#fff',
  primaryHover: '#00289a',
  primaryHoverText: '#fff',
  primaryActive: '#4769c6',

  secondary: '#e6dede',
  secondaryText: '#000',
  secondaryHover: '#b7b4b4',
  secondaryHoverText: '#000',
  secondaryActive: '#a4a5ab',

  tertiary: '#e2e8f0',
  tertiaryHover: '#c2d5ee',

  text: '#000',
  darkGrayText: '#4d4a4a',
  grayText: '#6d6b6b',

  slate: {
    500: 'rgba(166, 176, 191, 1.0)',
    600: 'rgba(129, 143, 164, 1.0)',
    700: 'rgba(83, 101, 130, 1.0)',
    800: 'rgba(52, 74, 108, 1.0)',
    900: 'rgba(22, 47, 86, 1.0)',
    910: 'rgba(22, 47, 86, 0.02)',
    920: 'rgba(22, 47, 86, 0.05)',
    930: 'rgba(22, 47, 86, 0.10)',
    940: 'rgba(22, 47, 86, 0.24)',
    950: 'rgba(22, 47, 86, 0.38)',
    960: 'rgba(22, 47, 86, 0.54)',
    970: 'rgba(22, 47, 86, 0.74)',
    980: 'rgba(22, 47, 86, 0.87)',
  },

  grey: {
    300: 'rgba(249, 251, 254, 1.0)',
    400: 'rgba(237, 240, 245, 1.0)',
    500: 'rgba(231, 235, 241, 1.0)',
  },

  cloud: {
    800: 'rgba(228, 232, 250, 1.0)',
    900: 'rgba(224, 228, 249, 1.0)',
    910: 'rgba(224, 228, 249, 0.02)',
    920: 'rgba(224, 228, 249, 0.05)',
    930: 'rgba(224, 228, 249, 0.10)',
    940: 'rgba(224, 228, 249, 0.24)',
    950: 'rgba(224, 228, 249, 0.38)',
    960: 'rgba(224, 228, 249, 0.54)',
    970: 'rgba(224, 228, 249, 0.74)',
    980: 'rgba(224, 228, 249, 0.87)',
  },

  primaryPallete: {
    900: 'rgba(53, 81, 159, 1)',
    910: 'rgba(53, 81, 159, 0.02)',
    920: 'rgba(53, 81, 159, 0.05)',
    930: 'rgba(53, 81, 159, 0.10)',
    940: 'rgba(53, 81, 159, 0.24)',
    950: 'rgba(53, 81, 159, 0.38)',
    960: 'rgba(53, 81, 159, 0.54)',
    970: 'rgba(53, 81, 159, 0.74)',
    980: 'rgba(53, 81, 159, 0.87)',
  },

  red: {
    900: 'rgba(209, 45, 45, 1.0)',
    930: 'rgba(209, 45, 45, 0.10)',
    960: 'rgba(209, 45, 45, 0.54)',
  },
};

export default colors;
